import React, { Component } from 'react';
import parse from 'html-react-parser';
import './css/base.css';
import './css/custom.css';

export class SetUpAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account_type_choice: '',
            loading: true
        };

        (async () => fetch('api/Home/GetPage?pageName=SetUpAccount')
            .then(response => response.text())
            .then(data => {
                this.setState({ page: data, loading: false });
            }))();
    }

    render() {
        let contents = this.state.loading
            ? ''
            : this.state.page;

        return (
            <div className="grid-row" id="actionForm">
                <div className="column-full">
                    <a href="#" className="link-back" id="back">Back</a>
                </div>
                <div className="column-two-thirds">
                    {parse(contents, {
                        replace: domNode => {
                            if (domNode.name === 'input') {
                                delete domNode.attribs.onchange;
                                return (
                                    <input
                                        {...domNode.attribs}
                                        onChange={() => {
                                            this.setState({
                                                account_type_choice: domNode.attribs.value
                                            })}}
                                    />
                                );
                            }
                        }
                    })}
                    <div className="form-group">
                        <a href={this.state.account_type_choice === 'connect' ? '/connect' : '/create'} className="button">Continue</a>
                    </div>
                </div>
            </div>
        )
    }
}