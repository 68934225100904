import React, { Component } from 'react';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenu />
                <div id="global-header-bar"></div>
                <main role="main" id="content" tabIndex="-1">
                    <div className="phase-banner">
                        <p>
                            <strong className="phase-tag">Beta</strong>
                            <span> This is a new service - <a href="mailto:itservicedesk@england.nhs.uk">email us your feedback</a> will help us to improve it.</span>
                        </p>
                    </div>
                    {this.props.children}
                </main>
                <footer className="group js-footer" id="footer" role="contentinfo">
                    <div className="footer-wrapper">
                        <div className="footer-meta">
                            <p className="flat-menu">
                                <a href="https://improvement.nhs.uk/cookies/" className="menu-padded">Cookies</a>
                                <a href="https://improvement.nhs.uk/accessibility/">Accessibility</a>
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
