import React, { Component } from 'react';
import parse from 'html-react-parser';
import './css/base.css';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { loading: true };

        (async () => fetch('api/Home/GetPage?pageName=Home')
            .then(response => response.text())
            .then(data => {
                this.setState({ page: data, loading: false });
            }))();
    }

    render() {
        let contents = this.state.loading
            ? ''
            : this.state.page;

        return (<div className="grid-row">
            {parse(contents)}
                </div>);
    }
}
