import React, { Component } from 'react';
import './css/govuk-template.css';
import './css/custom.css';
import NHSLogo from './images/white-on-blue.jpg';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    render() {
        return (
            <header role="banner" id="global-header" className="with-proposition">
                    <div className="header-wrapper">
                        <div className="header-global">
                            <div className="header-logo">
                                <img src={NHSLogo} width="113" height="51"className="content" alt="NHS logo"/>
                            </div>
                        </div>
                        <div className="header-proposition">
                            <div className="content">
                                <nav id="proposition-menu">
                                    <a href="/" id="proposition-name">
                                        Access recorded patient safety event data
                                    </a>
                                </nav>
                            </div>
                        </div>
                    </div>
            </header>
        );
    }
}
