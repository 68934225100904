import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { SetUpAccount } from './components/SetUpAccount';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <Route exact path='/SetUpAccount' component={SetUpAccount} />
            <Route path='/connect' component={() => {
                window.location.href = 'https://apps.model.nhs.uk';
                return null;
            }} />
            <Route path='/create' component={() => {
                window.location.href = 'https://apps.model.nhs.uk/register';
                return null;
            }} />
      </Layout>
    );
  }
}
